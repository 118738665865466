import React from "react"

import Layout from "../Layout/Layout";

const NotFoundPage = () => (
  <Layout>
    <h3>NOT FOUND</h3>
    <p>Hmm... I'm not sure how you got here, but this URL is not found. I usually just try clicking other thing. Go ahead and try some other links on this page. Hopefully those work will work?</p>
  </Layout>
)

export default NotFoundPage
